/* eslint-disable  jsx-a11y/no-noninteractive-element-interactions */

import React, { useState, useMemo, useCallback } from 'react';
import { css } from '@emotion/core';
import { Flex } from 'rebass';
import classnames from 'classnames';
import sortBy from 'lodash/sortBy';
import Link from '~common/atoms/Link';
import Icon from '~common/atoms/Icon';
import { tierInfo, inclusionColors } from '~lib/inclusionConstants';
import { useInclusionsByTierQuery } from '~lib/hooks/sanity/useInclusionsQuery';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import { useThemeUI } from 'theme-ui';
import { P1, P2, Subheading } from '~common/atoms/typography';
import { useMobileScreenOnly } from '~lib/responsive';
import { renderFeaturedTiers } from 'components/Quote/QuoteResultsNew/shared/utils';

const CompareHospitalInclusionsTable = ({
  type,
  showTiers,
  showIcon,
  product,
  products,
  headerContent,
  children,
  resultType,
  ...props
}) => {
  const [selected, setSelected] = useState();
  const isMobile = useMobileScreenOnly();
  const { theme } = useThemeUI();

  if (products) {
    products = sortBy(products, obj => parseInt(obj.content.sortOrder));
  }

  const productType = product
    ? product.content.type
    : products[0]?.content?.type;

  const mapInclusion = useCallback(
    inclusion => {
      if (product) {
        const moreDetails = product.content.inclusions.find(
          i => i.inclusion.id === inclusion.id
        );

        return {
          body: inclusion.body || moreDetails.description,
          inclusionObj: {
            ...moreDetails,
            //NOTE: why some of types use capital letters ???
            type: moreDetails.type.toLowerCase(),
          },
        };
      }

      return undefined;
    },
    [product]
  );

  const unfilteredTiers = useInclusionsByTierQuery(productType, {
    map: mapInclusion,
  });

  const tiers = useMemo(() => {
    return type
      ? Object.entries(unfilteredTiers).reduce((acc, [tier, inclusions]) => {
          acc[tier] = inclusions.filter(
            inclusion => inclusion.inclusionObj.type === type
          );
          return acc;
        }, {})
      : unfilteredTiers;
  }, [type, unfilteredTiers]);

  if (Object.keys(tiers).length === 1) {
    showTiers = false;
  }

  const RowTitle = isMobile ? P2 : P1;

  const renderInclusions = ({ tier, nodes, label, color, textColor }) => (
    <React.Fragment key={tier}>
      {showTiers && tier !== 'other' && nodes && nodes.length ? (
        <tr
          className={classnames({
            'row-tier': showTiers,
            [`tier-${tier}`]: showTiers,
          })}
        >
          <td
            className="col-tier hide-mobile"
            colSpan={products.length + 1}
            css={css`
              background-color: ${color};
            `}
          >
            <Subheading
              p={{
                xs: '8px 11px',
                md: '8px 16px',
              }}
              variant="semiBold"
              color={textColor || 'white'}
            >
              {label}
            </Subheading>
          </td>
          <td
            className="col-tier hide-desktop"
            colSpan={products.length}
            css={css`
              background-color: ${color};
            `}
          >
            <P2
              p={{
                xs: '8px 11px',
                md: '8px 16px',
              }}
              variant="semiBold"
              color={textColor || 'white'}
            >
              {label}
            </P2>
          </td>
        </tr>
      ) : null}

      {nodes
        ? nodes.map(inclusion => {
            return (
              <React.Fragment key={inclusion.id}>
                <tr
                  className={classnames('row-content', {
                    'row-open': selected === inclusion.id,
                    'row-tier': showTiers,
                    [`tier-${tier}`]: showTiers,
                  })}
                >
                  {showIcon ? (
                    <td className="col-icon" style={{ position: 'relative' }}>
                      <Icon
                        name={inclusion.icon}
                        width="36px"
                        height="36px"
                        stroke={
                          type ? inclusionColors[type] : theme.colors.primary
                        }
                      />
                    </td>
                  ) : null}

                  <td
                    className="hide-mobile col-title"
                    onClick={() => {
                      if (inclusion.body) {
                        setSelected(s =>
                          s === inclusion.id ? null : inclusion.id
                        );
                      }
                    }}
                    style={{
                      cursor: `${inclusion.body ? 'pointer' : 'default'}`,
                    }}
                    colSpan={1}
                  >
                    <Flex
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      className="col-title-container"
                      color="text.dark"
                    >
                      <RowTitle
                        variant="medium"
                        style={{
                          maxWidth: '91%',
                        }}
                      >
                        {inclusion.title}
                      </RowTitle>
                      {inclusion.inclusionObj &&
                      inclusion.inclusionObj.type === 'included-p'
                        ? '**'
                        : null}
                      <Flex flexDirection="row" alignItems="baseline">
                        {product && inclusion.inclusionObj.comparisonTier ? (
                          <img
                            alt="badge"
                            src={
                              tierInfo[inclusion.inclusionObj.comparisonTier]
                                .image
                            }
                            width="16"
                            height="20"
                            style={{ marginRight: '24px' }}
                          />
                        ) : null}
                        {inclusion.body ? (
                          <Icon
                            name={
                              selected === inclusion.id
                                ? 'chevron-up'
                                : 'chevron-down'
                            }
                            stroke="#4a4a4a"
                            width="10px"
                            height="15px"
                            style={{
                              marginTop: '5px',
                              pointerEvents: 'none',
                            }}
                          />
                        ) : null}
                      </Flex>
                    </Flex>
                    {product &&
                    product.content.type !== 'hospital' &&
                    type !== 'excluded' ? (
                      <div className="show-mobile-block col-waiting-period-mobile">
                        Waiting period:{' '}
                        <em>{inclusion.inclusionObj.waitingPeriod}</em>
                        {type !== 'excluded' &&
                        product.content.type === 'extras' ? (
                          <div>
                            Limit: <em>{inclusion.inclusionObj.limit}</em>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </td>
                  <td
                    className="hide-desktop col-title"
                    onClick={() => {
                      if (inclusion.body) {
                        setSelected(s =>
                          s === inclusion.id ? null : inclusion.id
                        );
                      }
                    }}
                    style={{
                      cursor: `${inclusion.body ? 'pointer' : 'default'}`,
                    }}
                    colSpan={products ? products.length : 1}
                  >
                    <Flex
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      className="col-title-container"
                      color="text.dark"
                    >
                      <RowTitle variant="medium">{inclusion.title}</RowTitle>
                      <Flex
                        flexDirection="row"
                        alignItems="baseline"
                        flexShrink={0}
                      >
                        {inclusion.inclusionObj &&
                        inclusion.inclusionObj.type === 'included-p'
                          ? '**'
                          : null}
                        {product && inclusion.inclusionObj.comparisonTier ? (
                          <img
                            className="hide-desktop"
                            alt="badge"
                            src={
                              tierInfo[inclusion.inclusionObj.comparisonTier]
                                .image
                            }
                            width="13"
                            height="16"
                            style={{ marginRight: '8px' }}
                          />
                        ) : null}
                        {inclusion.body ? (
                          <>
                            <Icon
                              name={
                                selected === inclusion.id
                                  ? 'chevron-up'
                                  : 'chevron-down'
                              }
                              stroke="#4a4a4a"
                              height="15px"
                              style={{
                                marginRight: '-8px',
                                height: '15px !important',
                                pointerEvents: 'none',
                              }}
                            />
                          </>
                        ) : null}
                      </Flex>
                    </Flex>
                    {product &&
                    product.content.type !== 'hospital' &&
                    type !== 'excluded' ? (
                      <div className="show-mobile-block col-waiting-period-mobile">
                        Waiting period:{' '}
                        <em>{inclusion.inclusionObj.waitingPeriod}</em>
                        {type !== 'excluded' &&
                        product.content.type === 'extras' ? (
                          <div>
                            Limit: <em>{inclusion.inclusionObj.limit}</em>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </td>
                  {product &&
                  product.content.type !== 'hospital' &&
                  type !== 'excluded' ? (
                    <td
                      className="hide-mobile col-waiting_period"
                      style={{ position: 'relative' }}
                    >
                      Waiting period
                      <strong>{inclusion.inclusionObj.waitingPeriod}</strong>
                    </td>
                  ) : null}
                  {product &&
                  product.content.type === 'extras' &&
                  type !== 'excluded' ? (
                    <td className="hide-mobile col-limit">
                      Total limit
                      <strong>{inclusion.inclusionObj.limit}</strong>
                    </td>
                  ) : null}
                  {products
                    ? products.map(p => {
                        const productInclusion = p.content.inclusions.find(
                          pi => pi.inclusion._id === inclusion.id
                        );

                        if (
                          productInclusion &&
                          (productInclusion.type.toLowerCase() === 'included' ||
                            productInclusion.type.toLowerCase() ===
                              'included-p')
                        ) {
                          return (
                            <td
                              className="hide-mobile col-inclusion"
                              key={p.id}
                            >
                              <Icon
                                name="circle-fill-tick"
                                fill="#569b07"
                                width="17px"
                                height="17px"
                                style={{ margin: '0 auto' }}
                                data-content={
                                  productInclusion.type.toLowerCase() ===
                                  'included-p'
                                    ? '**'
                                    : ''
                                }
                              />
                            </td>
                          );
                        }

                        if (
                          productInclusion &&
                          productInclusion.type.toLowerCase() === 'restricted'
                        ) {
                          return (
                            <td
                              className="hide-mobile col-inclusion"
                              key={p.id}
                            >
                              <Icon
                                name="restricted"
                                fill="#f5a623"
                                width="17px"
                                height="17px"
                                style={{ margin: '0 auto' }}
                              />
                            </td>
                          );
                        }

                        if (
                          productInclusion &&
                          productInclusion.type.toLowerCase() ===
                            'restricted-accident'
                        ) {
                          return (
                            <td
                              className="hide-mobile col-inclusion"
                              key={p.id}
                            >
                              accident only
                            </td>
                          );
                        }

                        if (
                          productInclusion &&
                          productInclusion.type.toLowerCase() === 'excluded'
                        ) {
                          return (
                            <td
                              className="hide-mobile col-inclusion"
                              key={p.id}
                            >
                              <Icon
                                name="remove"
                                fill="#d0021b"
                                width="17px"
                                height="17px"
                                style={{ margin: '0 auto' }}
                              />
                            </td>
                          );
                        }
                      })
                    : null}
                </tr>
                {selected && selected === inclusion.id && inclusion.body ? (
                  <tr
                    key={`${inclusion.id}-open`}
                    className={classnames('row-details', {
                      'row-tier': showTiers,
                      [`tier-${tier}`]: showTiers,
                    })}
                  >
                    {showIcon ? <td className="col-icon" /> : null}
                    <td
                      className="hide-mobile col-details"
                      colSpan={1} //mobile products.length
                    >
                      <SanityRichTextContent
                        content={inclusion.body}
                        css={css`
                          font-size: 14px;
                          padding: 0 16px 16px ${showIcon ? '0' : '12px'};

                          @media (min-width: 992px) {
                            padding: 0 16px 16px;
                          }

                          a {
                            color: #1d4b8d;
                            font-weight: 600;
                          }

                          p {
                            font-size: 14px;
                            margin-bottom: 6px;
                          }

                          ul,
                          ol {
                            margin-top: 6px;
                            padding-left: 24px;

                            > li {
                              font-size: 14px;
                              margin-bottom: 2px;
                              padding-left: 0;
                            }
                          }
                        `}
                      />
                    </td>
                    <td
                      className="hide-desktop col-details"
                      colSpan={products ? products.length : 1}
                    >
                      <SanityRichTextContent
                        content={inclusion.body}
                        css={css`
                          font-size: 14px;
                          padding: 0 15px 15px 0;

                          @media (max-width: 992px) {
                            padding: 0 16px 15px ${showIcon ? '0' : '12px'};
                          }
                          a {
                            color: ${theme.colors.secondary};
                            font-weight: 600;
                          }

                          p {
                            font-size: 14px;
                            margin-bottom: 6px;
                          }

                          ul,
                          ol {
                            margin-top: 6px;
                            padding-left: 24px;

                            > li {
                              font-size: 14px;
                              margin-bottom: 2px;
                              padding-left: 0;
                            }
                          }
                        `}
                      />
                    </td>
                    {product &&
                    product.content.type !== 'hospital' &&
                    type !== 'excluded' ? (
                      <td className="hide-mobile col-waiting_period" />
                    ) : null}
                    {product &&
                    product.content.type === 'extras' &&
                    type !== 'excluded' ? (
                      <td className="hide-mobile col-limit" />
                    ) : null}
                    {products &&
                      products.map(p => (
                        <td key={p.id} className="product-cell hide-mobile" />
                      ))}
                  </tr>
                ) : null}
                <tr
                  className={classnames('row-products-mobile', 'hide-desktop', {
                    'row-tier': showTiers,
                    [`tier-${tier}`]: showTiers,
                    'row-open': selected === inclusion.id,
                  })}
                >
                  {products
                    ? products.map(p => {
                        const productInclusion = p.content.inclusions.find(
                          pi => pi.inclusion._id === inclusion.id
                        );

                        if (
                          productInclusion &&
                          (productInclusion.type.toLowerCase() === 'included' ||
                            productInclusion.type.toLowerCase() ===
                              'included-p')
                        ) {
                          return (
                            <td key={p.id} className="col-inclusion">
                              <Icon
                                name="circle-fill-tick"
                                fill="#569b07"
                                width="17px"
                                height="17px"
                                style={{ margin: '0 auto' }}
                                dataContent={
                                  productInclusion.type.toLowerCase() ===
                                  'included-p'
                                    ? '**'
                                    : ''
                                }
                              />
                            </td>
                          );
                        }

                        if (
                          productInclusion &&
                          productInclusion.type.toLowerCase() === 'restricted'
                        ) {
                          return (
                            <td key={p.id} className="col-inclusion">
                              <Icon
                                name="restricted"
                                fill="#f5a623"
                                width="17px"
                                height="17px"
                                style={{ margin: '0 auto' }}
                              />
                            </td>
                          );
                        }

                        if (
                          productInclusion &&
                          productInclusion.type.toLowerCase() ===
                            'restricted-accident'
                        ) {
                          return (
                            <td
                              className="hide-mobile col-inclusion"
                              key={p.id}
                            >
                              accident only
                            </td>
                          );
                        }

                        if (
                          productInclusion &&
                          productInclusion.type.toLowerCase() === 'excluded'
                        ) {
                          return (
                            <td key={p.id} className="col-inclusion">
                              <Icon
                                name="remove"
                                fill="#d0021b"
                                width="17px"
                                height="17px"
                                style={{ margin: '0 auto' }}
                              />
                            </td>
                          );
                        }
                      })
                    : null}
                </tr>
              </React.Fragment>
            );
          })
        : null}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <table
        css={css`
          width: 100%;
          border: none;
          table-layout: fixed;

          color: ${theme.colors.light};
          border-top: 2px solid ${type ? inclusionColors[type] : 'transparent'};
          position: relative;

          thead,
          tbody,
          tfoot,
          tr {
            width: 100%;
          }
          thead {
            tr {
              .col-title {
                vertical-align: top;
              }

              td {
                &.col-product {
                  @media (min-width: 992px) {
                    width: 160px;
                  }
                }

                &.col-product-first {
                  .card-top,
                  .card-bottom {
                    border-left: 2px solid ${theme.colors.primary} !important;
                  }
                }

                &.col-product-last {
                  .card-top {
                    border-right: 2px solid ${theme.colors.primary} !important;
                  }
                }
              }
            }
          }
          tbody {
            border: 1px solid ${theme.colors.neutral50};
            background-color: ${theme.colors.white};

            tr {
              border-bottom: 1px solid ${theme.colors.neutral50};

              td {
                height: ${showIcon ? '60px' : '40px'};
                padding: 0;
                margin: 0;
                vertical-align: middle;
                cursor: default;

                @media (max-width: 992px) {
                  height: 60px;
                }

                &.col-icon {
                  width: 60px;
                  padding-right: 10px;

                  @media (max-width: 992px) {
                    width: 40px;
                    padding-right: 5px;

                    div,
                    svg {
                      width: 24px;
                    }
                  }

                  > div {
                    margin: 0 auto;
                  }
                }

                &.col-inclusion {
                  text-align: center;
                  font-size: 12px;
                  text-transform: lowercase;
                }

                &.col-inclusion.hide-mobile,
                &.col-link {
                  border-left: 1px solid #ececec;
                }

                &.col-title {
                  padding: 8px 16px;
                  padding-left: ${showIcon ? '0' : '11px'};

                  @media (min-width: 992px) {
                    padding: 16px;
                    padding-left: ${showIcon ? '0' : '16px'};
                  }

                  .col-waiting-period-mobile {
                    margin-top: 1px;
                    font-size: 14px;
                    line-height: 18px;

                    em {
                      font-style: italic;
                    }
                  }
                }

                &.col-tier {
                  height: 30px;
                }

                &.col-waiting_period,
                &.col-limit {
                  width: 130px;
                  border-left: 1px solid ${theme.colors.neutral50};
                  padding: 0 15px;
                  font-size: 13px;
                  line-height: 1.4;

                  strong {
                    display: block;
                    font-weight: 700;
                  }
                }

                &.col-limit {
                  width: 164px;
                }

                &.col-details {
                  padding-left: 0;

                  ${props => props.mq.md} {
                    padding-left: ${showIcon ? '0' : '16px'};
                  }
                }
              }

              &.row-open {
                border-bottom: none;

                .col-title {
                  font-weight: 700;
                }

                .col-waiting-period-mobile {
                  font-weight: 400;
                }
              }

              &.row-tier {
                &.tier-basic > td.col-title,
                &.tier-basic > td.col-details,
                &.tier-basic > td.col-tier,
                &.tier-basic > td.col-inclusion:first-of-type {
                  border-left: 5px solid ${tierInfo.basic.color};
                }
                &.tier-bronze > td.col-title,
                &.tier-bronze > td.col-details,
                &.tier-bronze > td.col-tier,
                &.tier-bronze > td.col-inclusion:first-of-type {
                  border-left: 5px solid ${tierInfo.bronze.color};
                }
                &.tier-silver > td.col-title,
                &.tier-silver > td.col-details,
                &.tier-silver > td.col-tier,
                &.tier-silver > td.col-inclusion:first-of-type {
                  border-left: 5px solid ${tierInfo.silver.color};
                }
                &.tier-gold > td.col-title,
                &.tier-gold > td.col-details,
                &.tier-gold > td.col-tier,
                &.tier-gold > td.col-inclusion:first-of-type {
                  border-left: 5px solid ${tierInfo.gold.color};
                }
              }

              &.row-content {
                @media (max-width: 992px) {
                  border-bottom: none;

                  > td {
                    height: 30px;
                  }
                }
              }

              &.row-products-mobile {
                > td {
                  height: 40px;
                }
              }

              &.row-open,
              &.row-details {
                td {
                  background-color: ${theme.colors.background.light};
                }
              }

              &.row-details {
                @media (max-width: 992px) {
                  border-bottom: none;
                }
              }
            }
          }
        `}
        {...props}
      >
        <tbody>
          {renderFeaturedTiers(tiers)(renderInclusions)}

          {['basic', 'bronze', 'silver', 'gold'].map(tier => {
            return renderInclusions({
              tier,
              nodes: tiers[tier],
              color: tierInfo[tier].color,
              textColor: tierInfo[tier].textColor,
              label: tierInfo[tier].label,
            });
          })}
          {products && products.length && (
            <tr className="row-links hide-mobile">
              <td className="hide-mobile" />
              {products.map(p => {
                return (
                  <td key={`details-link-${p.id}`} className="col-link">
                    <Flex justifyContent="center" alignItems="center">
                      <P1
                        variant="semiBold"
                        as={Link}
                        to={`/${p.content.slug.current}`}
                        color="primary"
                      >
                        More details
                      </P1>
                    </Flex>
                  </td>
                );
              })}
            </tr>
          )}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default CompareHospitalInclusionsTable;
